import { IonCol, IonRow, useIonAlert } from '@ionic/react'
import React, { FC } from 'react'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { BlueButton, RedButton } from '../../incident-management/StyledContainers'
import IssueScreen from '../popovers/IssueScreen'
import { getColor } from './FlashpointList'
import axios from 'axios'
import { useWorkspace } from '../../../hooks/useWorkspace'

const IssuesList : FC = () => {
  const { issues, focusedElems, dispatchFocused } = useTerrainMapping()
  const [ionAlert] = useIonAlert()
  const { workspace } = useWorkspace()

  if (focusedElems.focusedIssue) {
    return (
      <IssueScreen />
    )
  }

  const handleDelete = (e: { id: string }) => {
    ionAlert({
      header: 'Confirmation',
      message: 'Are you sure you want to delete this Issue?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            console.log('Delete canceled')
          },
        },
        {
          text: 'Yes',
          handler: () => {
            console.log('Delete', e)
            axios.put('/api/v2/stakeholder/delete_stakeholdr', {
              domain_id: workspace.id,
              stakeholder_id: e.id,
            }).then(({ data }) => {
              console.log('Success:', data)
              // Handle success case here
            }).catch((error) => {
              console.error('Error:', error)
              // Handle error case here
            })
          },
        },
      ],
    })
  }

  return (
    <ListContainer style={{ backgroundColor: 'white', height: '100%' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <IonRow style={{ padding: '10px' }} className='terrain-list-title  ion-justify-content-between ion-align-items-center'>
          <IonCol size='2'>
            <h5>Title</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Description</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Type</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Associated Groups</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Associated Areas</h5>
          </IonCol>
          <IonCol size='2' />
        </IonRow>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {
            issues.map((val, index) => (
              <IonRow
                className='ion-justify-content-between terrain-register-row ion-align-items-center'
                style={{ backgroundColor: getColor(index) }}
                key={val.id}
              >
                <IonCol size='2'>{ val.title }</IonCol>
                <IonCol size='2' style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 4,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  {val.description}
                </IonCol>
                <IonCol size='2'>{ val.type }</IonCol>
                <IonCol size='2'>{ (val.stakeholders.length === 0) ? 'No Groups selected' : val.stakeholders.map(({ name }) => name).join(', ') }</IonCol>
                <IonCol size='2'>{ (val.communities.length === 0) ? 'No Areas selected' : val.communities.map(({ name }) => name).join(', ') }</IonCol>
                <IonCol size='2'>
                  <BlueButton onClick={() => dispatchFocused({ type: FocusedActionKind.SELECT_ISSUE, issue: val })}>View</BlueButton> / <RedButton onClick={() => handleDelete(val)}>Delete</RedButton>
                </IonCol>
              </IonRow>
            ))
          }
          {
            issues.length === 0 && (
              <p style={{ textAlign: 'center' }}>No issues available</p>
            )
          }
        </div>
      </div>
    </ListContainer>
  )
}

export default IssuesList
