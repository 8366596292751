/* eslint-disable */
import { act } from '@testing-library/react'
import axios from '../utils/axios'
/*testing*/
const testFunctionality = () => {
  const tryIncident = (name, description, longitude, latitude, reported, incident_type, fatalities, domain_id, county_id, nearest) => axios.post('/api/v1/incident', {
    name, description, longitude, latitude, reported, incident_type, fatalities, domain_id, county_id, nearest
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const addBox = (advanced_notice_hours, check_in_minutes, senior_manager_approval, executive_manager_approval, security_escort, anti_vampire_rules, convoy, forbidden, geoData, from, to, check_in_days, description, domain_id, measures, zone_type, users, threats, causes) => axios.post('/api/v1/movement_box/create', {
    advanced_notice_hours, check_in_minutes, senior_manager_approval, executive_manager_approval, security_escort, anti_vampire_rules, convoy, forbidden, geoData, from, to, check_in_days, description, domain_id, measures, zone_type, users, threats, causes
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const tryInsight = (name, description, longitude, latitude, reported, needed, optional, county, domain_id) => axios.post('/api/v1/insight', {
    name, description, longitude, latitude, reported, needed, optional, county, domain_id, mobile: false,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getIncidents = (domain_id) => axios.post('/api/v1/get_incident', { domain_id })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getIncidents2 = (domain_id, period) => axios.post('/api/v1/get_incident', { domain_id, period })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getIncidentsForCounty = (options) => axios.post('/api/v1/incidents_single_county', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const getIncidentsForDomain = (options) => axios.post('/api/v1/incidents_by_domain', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getInsightsForDomain = (options) => axios.post('/api/v1/insight/insights_by_domain', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getBoxMeasures = () => axios.post('/api/v1/movement_box/getMeasures')
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const getUserList = (options) => axios.post('/api/v1/movement_box/getUserList', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const trackerResponse = (options) => axios.post('/api/v1/movement_box/trackerResponse', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const trackerHit = (options) => axios.post('/api/v1/movement_box/trackerHit', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const ReportsIncidentPopup = (options) => axios.post('/api/v1/report_popup_data', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const dashboardInsights = (options) => axios.post('/api/v1/domain/dashboard', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const dashboardActivity = (options) => axios.post('/api/v1/domain/activityDashboard', {
      ...options,
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })
  
  const dashboardJourneys = (options) => axios.post('/api/v1/domain/journeysDashboard', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const dashboardEngagements = (options) => axios.post('/api/v1/domain/engagementsDashboard', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const weeklyReport = (options) => axios.post('/api/v1/domain/weeklyReport', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const weeklyReportPDF = (options) => axios.post('/api/v1/domain/weeklyReportPDF', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const weeklyDates = (options) => axios.get('/api/v1/domain/weeklyDates', {
      ...options,
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const tester = (options) => axios.get('/api/v1/domain/tester', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const anonymiseUsers = (options) => axios.put('/api/v1/domain/anonymiseUsers', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const MetricByArchangel = (options) => axios.get('/api/v1/domain/MetricByArchangel', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const systemSafeties = (options) => axios.get('/api/v1/domain/systemSafeties', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })


  const systemPulsepoints = (options) => axios.get('/api/v1/domain/systemPulsepoints', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getIncidentsByCounty = () => axios.get('/api/v1/incidents_by_county', {})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getCounties = (domain_id) => axios.post('/api/v1/county', { domain_id })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getEfficientCounties = (options) => axios.post('/api/v1/county/efficient_counties', {
     ...options 
    })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getAllCounties = (options) => axios.get('/api/v1/county/all_counties', {
    ...options 
   })
   .then((response) => Promise.resolve(response.data))
   .catch((error) => {
     console.debug(error)
   })

  const getAllCountries = (options) => axios.get('/api/v1/county/all_countries', {
   ...options 
  })
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const getAllRegions = (options) => axios.get('/api/v1/county/all_regions', {
   ...options 
  })
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const downloadSecurityChange = (options) => axios.post('/api/v1/county/download_security_change', {
    ...options, //responseType: 'arraybuffer'
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const securityPopup = (options) => axios.post('/api/v1/county/security_popup', {
    ...options, //responseType: 'arraybuffer'
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const situationReport = (options) => axios.post('/api/v1/county/situation_report', {
    ...options, //responseType: 'arraybuffer'
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
 
  const getPaths = (domain_id) => axios.post('/api/v1/path/retrieve_all', {id: domain_id})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    }) 

  const tryPath = (name, origin, destination, geoData, domain_id, route_type) => axios.post('/api/v1/path/submit_one', {
    name, origin, destination, geoData, domain_id, route_type,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
    
  const assignTask = (title, description, priority, due, app, repeat, assignees, domain_id) => axios.post('/api/v1/task/create', {
    title, description, priority, due, app, repeat, assignees, domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getUsers = (domain_id) => axios.post('get_users', {
    domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const validateUsers = (options) => axios.post('validate_users', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const validateGroups = (options) => axios.post('api/v2/stakeholder/validate_groups', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })


  const updateStatus = (id, status, domain_id) => axios.put('/api/v1/task/update_status', {
    id, status, domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
    
  const getSomeTasks = (domain_id) => axios.post('/api/v1/task/get_specific', {
    domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getArchivedTasks = (domain_id) => axios.post('/api/v1/task/get_archived', {
    domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const archiveTask = (id, domain_id) => axios.put('/api/v1/task/archive', {
    id, domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
    
  const editTask = (tasks) => axios.put('/api/v1/task/edit', {
    tasks
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editUser = (options) => axios.put('/edit_profile', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const changePassword = (options) => axios.put('/change_password', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const passwordRecovery = (options) => axios.put('/passwordRecovery', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createMessage = (options) => axios.post('/create_message', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createRoom = (options) => axios.post('/create_room', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createBreakout = (options) => axios.post('/create_breakout', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const leaveBreakout = (options) => axios.put('/leave_breakout', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const leaveChat = (options) => axios.put('/leave_chat', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const changeHost = (options) => axios.put('/change_host', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getRooms = () => axios.get('/get_rooms', {})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getBreakouts = (options) => axios.post('/get_breakouts', { ...options })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  

  const getMessages = (options) => axios.post('/get_messages', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editBreakout = (options) => axios.post('/edit_breakout', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getJourneys = (domain_id) => axios.post('/api/v1/journey/Retrieve', {
    id: domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getAirJourneys = (domain_id) => axios.post('/api/v1/journey/RetrieveAir', {
    id: domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getAirChapters = (journey_id) => axios.post('/api/v1/journey/RetrieveAirChapters', {
    id: journey_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const requestJourney = (name, approver, purpose, leader_id, leader_phone, driver_name, driver_phone, vehicle_type, vehicle_reg, emergency_sat, emergency_other, arrival_time, arrival_location, departure_time, departure_location, passengers, passenger_hotel, passenger_location, passenger_contact, driver_hotel, driver_location, driver_contact, stages, domain_id) => axios.post('/api/v1/journey/Request', {
    name, approver, purpose, leader_id, leader_phone, driver_name, driver_phone, vehicle_type, vehicle_reg, emergency_sat, emergency_other, arrival_time, arrival_location, departure_time, departure_location, passengers, passenger_hotel, passenger_location, passenger_contact, driver_hotel, driver_location, driver_contact, stages, domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const requestAirPreTravel = (approver, purpose, departure_date, from, to, stages, comment, fromLng, fromLat, toLng, toLat, domain_id) => axios.post('/api/v1/journey/airPreTravel_request', {
    approver, purpose, departure_date, from, to, stages, comment, fromLng, fromLat, toLng, toLat, domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const editAirPreTravel = ( approver, purpose, departure_date, from, to, comment, fromLng, fromLat, toLng, toLat, domain_id, journey_id ) => axios.put('/api/v1/journey/edit_pretravel_request', {
    approver, purpose, departure_date, from, to, comment, fromLng, fromLat, toLng, toLat, domain_id, journey_id,
  })
  .then((response) => Promise.resolve(response.data))
  .catch((error) => console.debug(error))

  const requestAirFlightReq = (airline_name, flight_no, flight_departure_date, passengers, journey_id, domain_id) => axios.post('/api/v1/journey/flightReq_request', {
    airline_name, flight_no, flight_departure_date, passengers, id: journey_id, domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  

  const progressJourney = (id,reason) => axios.put('/api/v1/journey/Progress', {
    id,reason
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const progressAirJourney = (id,reason,domain_id) => axios.put('/api/v1/journey/ProgressAir', {
    id,reason,domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const denyJourney = (id,reason) => axios.put('/api/v1/journey/Deny', {
    id,reason
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const denyAirJourney = (id,reason,domain_id) => axios.put('/api/v1/journey/DenyAir', {
    id,reason,domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  const abortAirJourney = (id, domain_id) => axios.put('/api/v1/journey/abort_air', {
    id, domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const activateAccount = ( search ) => axios.post(`/activate${search}`).
  then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const commentJourney = (id,comment, latitude, longitude) => axios.put('/api/v1/journey/Comment', {
    id,comment, latitude, longitude,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const commentAirJourney = (id, comment, domain_id) => axios.put('/api/v1/journey/comment_air', {
    id, comment, domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getRolesbyUser = (id) => axios.post('/api/v1/domain/get_roles', {
    id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const tryWorkspace = (progenitor, name, country) => axios.post('/api/v1/domain/spawn', {
    progenitor,name,country
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const inviteToWorkspace = (id, emails) => axios.post('/api/v1/domain/invite', {
    id,emails
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const processWorkspaceInvite = (id, accept) => axios.post('/api/v1/domain/process_invitation', {
    id,accept
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const promoteInWorkspace = (id, users) => axios.put('/api/v1/domain/promote', {
    id,users
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const seeWorkspace = () => axios.get('/api/v1/domain/omniscience', {
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const demoteInWorkspace = (id, users) => axios.put('/api/v1/domain/demote', {
    id,users
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const killWorkspace = (id) => axios.put('/api/v1/domain/kill', {
    id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const incarnateIntoWorkspace = (id) => axios.post('/api/v1/domain/incarnate', {
    id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editWorkspace = (options) => axios.put('/api/v1/domain/edit_workspace', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const editJourney = (id, options) => axios.put('/api/v1/journey/edit_journey', {
    ...options, journey_id: id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    }) 

  const getWorkspace = (id) => axios.post('/api/v1/domain/get_one', {
    id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getMovebox = (id) => axios.post('/api/v1/movement_box/boxes', {
    id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editIncident = (options) => axios.put('/api/v1/edit_incident', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editAlertState = (options) => axios.put('/api/v1/county/edit_alert_state', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const editMovementBox = (options) => axios.put('/api/v1/movement_box/edit_movement_box', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    }) 
  
  const archiveMovementBox = (options) => axios.put('/api/v1/movement_box/archiveMovementBox', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    }) 

  const get_avg_alert = (domain_id, timeframe) => axios.put('/api/v1/county/get_avg_alert', {
    domain_id, timeframe
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    }) 
  
  const banishUsers = (id, users) => axios.put('/api/v1/domain/banish', {
    id,users
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const anointUser = (id) => axios.put('/api/v1/domain/anoint', {
    id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const resetPassword = (email) => axios.put('/mission_impossible', {
    email
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const deleteIncident = (options) => axios.put('/api/v1/delete_incident', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    }) 

  const incidentsPerArchangel = (options, overseer_id) => axios.post('/api/v1/incident/incidents_per_archangel', {
    ...options, overseer_id
  })
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const createProject = (title, deadline, domain_id, problem, goal, start, project_type, user_id, summary, support, assets, communities, stakeholders, team_members, project_lead) => axios.post('/api/v1/project/create', {
    title, deadline, domain_id, problem, goal, start, project_type, user_id, summary, support, assets, communities, stakeholders, team_members, project_lead
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const projectDropdowns = (options) => axios.post('/api/v1/project/project_dropdowns', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const addMetrics = (options) => axios.post('/api/v1/metric/create', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
  })

  const getProjects = (domain_id) => axios.post('/api/v1/project/retrieve', {
    domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const archiveUserMetric = (options) => axios.put('/api/v1/metric/archiveUserMetric', {
    ...options
  })
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const archiveMetric = (options) => axios.put('/api/v1/metric/archiveMetric', {
    ...options
  })
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const editMetric = (options) => axios.put('/api/v1/metric/editMetric', {
    ...options
  })
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const editUserMetric = (options) => axios.put('/api/v1/metric/editUserMetric', {
    ...options
  })
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const createUserMetric = (options) => axios.post('/api/v1/metric/createUserMetric', {
    ...options
  })
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const getMetricDetailed = (options) => axios.post('/api/v1/metric/getMetricDetailed', {
    ...options
  })
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const getProjectDetails = (project_id) => axios.post('/api/v1/project/details', {
    project_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const insightsPerArchangel = (options, overseer_id) => axios.post('/api/v1/insight/insights_per_archangel', {
    ...options, overseer_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createObjective = (project_id, objectives) => axios.post('/api/v1/project/add_objective', {
    project_id, objectives
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
  })

  const totalArchangels = (options) => axios.get('/api/v1/domain/total_archangels', {
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const progress_objective = (objective_id) => axios.put('/api/v1/project/objective/progress', {
    objective_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const regress_objective = (objective_id) => axios.put('/api/v1/project/objective/regress', {
    objective_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const abort_objective = (objective_id) => axios.put('/api/v1/project/objective/abort', {
    objective_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const progress_project = (project_id) => axios.put('/api/v1/project/progress', {
    project_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getMetrics = (options) => axios.post('/api/v1/project/getMetrics', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const totalUsers = () => axios.get('total_users', {
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const regress_project = (project_id) => axios.put('/api/v1/project/regress', {
    project_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getIrisMetrics = () => axios.get('/api/v1/project/getIrisMetrics', {})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const abort_project = (project_id) => axios.put('/api/v1/project/abort', {
    project_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const addTaskToObjective = (objective_id, tasks) => axios.post('/api/v1/project/add_task', {
    objective_id, tasks
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editProject = (options) => axios.put('/api/v1/project/edit_project', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editObjective = (options) => axios.put('/api/v1/project/edit_objective', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const deleteObjectives = (options) => axios.post('/api/v1/project/delete_objectives', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getUserMetrics = (options) => axios.post('/api/v1/project/getUserMetrics', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const userArchangel = (overseer_id) => axios.post('user_archangel', {
    overseer_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const createAsset = (options) => axios.post('/api/v1/asset/createAsset', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const dashboardAssets = (options) => axios.post('/api/v1/asset/assetsDashboard', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createAssetRisk = (options) => axios.post('/api/v1/asset/createAssetRisk', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createRisk = (options) => axios.post('/api/v1/asset/createRisk', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createMitigation = (options) => axios.post('/api/v1/asset/createMitigation', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editAsset = (options) => axios.put('/api/v1/asset/editAsset', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const addControlMeasure = (options) => axios.post('/api/v1/asset/addControlMeasure', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const deprecateControlMeasure = (options) => axios.put('/api/v1/asset/deprecateControlMeasure', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getFormMeasures = (options) => axios.post('/api/v1/asset/getFormMeasures', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const removeMitigation = (options) => axios.put('/api/v1/asset/removeMitigation', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editAssetRisk = (options) => axios.put('/api/v1/asset/editAssetRisk', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getAssets = (domain_id) => axios.post('/api/v1/asset/get_assets', {
    domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const validateAssets = (options) => axios.post('/api/v1/asset/validate_assets', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getAssetsByCounty = (options) => axios.post('/api/v1/asset/assets_by_county', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const downloadAssets = (options) => axios.post('/api/v1/asset/downloadAssets', {
    ...options, //responseType: 'arraybuffer'
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const downloadRisks = (options) => axios.post('/api/v1/asset/downloadRisks', {
    ...options, //responseType: 'arraybuffer'
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getAsset = (id) => axios.post('/api/v1/asset/get_asset', {
    id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getMitigations = (asset_risk) => axios.post('/api/v1/asset/get_mitigations', {
    asset_risk
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const riskTypes = (domain_id) => axios.post('/api/v1/asset/get_risk_types', {
    domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const assetTypes = () => axios.get('/api/v1/asset/get_asset_types', {
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const archiveAssets = (assets) => axios.put('/api/v1/asset/archiveAssets', {
    assets
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
    
  const archiveAssetRisks = (asset_risks) => axios.put('/api/v1/asset/archiveAssetRisks', {
    asset_risks
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getAssetDetails = (id) => axios.post('/api/v1/asset/getAllAssetDetails', {
    id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getCountiesOfInterest = (domain_id) => axios.post('/api/v1/domain/getInterests', {
    domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const declareInterestInCounties = (counties,domain_id) => axios.post('/api/v1/domain/declareInterest', {
    counties,domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const denounceInterestInCounties = (counties,domain_id) => axios.post('/api/v1/domain/denounceInterest', {
    counties,domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const help = (options) => axios.post('/api/v1/helpcenter/help', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getEmail = (user_id) => axios.post('get_email', {
    user_id
  })

  const archivePath = (path_id) => axios.post('/api/v1/path/archive', {
    path_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createRiskMetric = (edit, id) => axios.post('/api/v1/asset/createRiskMetric', {
    edit, id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getAllSafeties = (domain_id) => axios.post('/api/v1/domain/safety_check', {
    domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const userStats = (domain_id) => axios.post('/user_stats', {
    domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
 
  const promptAllSafeties = (domain_id) => axios.post('/api/v1/domain/safety_prompt', {
    domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const prompUserSafeties = (user_ids, domain_id) => axios.post('/api/v1/domain/users_safety_promp', {
    user_ids, domain_id
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const submitOneSafety = (options) => axios.post('/safety_check', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
 
  const promptOneSafety = (user_id, domain_id) => axios.post('safety_prompt', {
    user_id, domain_id
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  function s2ab2(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  function downloader2(data, filename) {

    var blob = new Blob([s2ab2(atob(data))], {
      type: "application/pdf"
    });


    //if using send_data, create file to download with blob
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = filename
    link.href = URL.createObjectURL(blob)

    //document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
  }

  const getLunar = () => axios.get('/retrieve_lunar', {})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getKeyphrases = () => axios.get('/api/v1/county/retrieve_keyphrases', {})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getAnalyses = () => axios.get('/api/v1/county/retrieve_analyses', {})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const addAnalyses = (recommendation, alerts, keyphrases) => axios.post('/api/v1/county/submit_analysis', {
    recommendation, alerts, keyphrases,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
 
   const getMyAnalyses = () => axios.get('/api/v1/county/retrieve_my_analyses', {})
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
 
  const getInactive = () => axios.get('/retrieve_inactive', {})
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const getLost = () => axios.get('/retrieve_lost', {})
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

  const getLocationHistory = (options) => axios.get('/getLocationHistory', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createInternalIncident = (options) => axios.post('/api/v1/internal_incident/create', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const downloadInternalIncident = (options) => axios.post('/api/v1/internal_incident/download', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const setEmergencyTeams = (options) => axios.post('/api/v1/internal_incident/setEmergencyTeams', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getEmergencyTeams = (options) => axios.post('/api/v1/internal_incident/getEmergencyTeams', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const setResponseLevels = (options) => axios.post('/api/v1/internal_incident/setResponseLevels', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getResponseLevels = (options) => axios.post('/api/v1/internal_incident/getResponseLevels', {
    ...options
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createActionLog = (options) => axios.post('/api/v1/internal_incident/createActionLog', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const createInvestigation = (options) => axios.post('/api/v1/internal_incident/createInvestigation', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })
  
  const voidActionLog = (options) => axios.put('/api/v1/internal_incident/voidActionLog', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const closeIncident = (options) => axios.put('/api/v1/internal_incident/closeIncident', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const addInvestigationPeople = (options) => axios.post('/api/v1/internal_incident/addInvestigationPeople', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const editInvestigation = (options) => axios.put('/api/v1/internal_incident/editInvestigation', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const incidentComment = (options) => axios.post('/api/v1/internal_incident/incidentComment', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const fetchInternalIncident = (options) => axios.post('/api/v1/internal_incident/fetchInternalIncident', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const fetchInternalIncidents = (options) => axios.post('/api/v1/internal_incident/fetchInternalIncidents', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const editProcedures = (options) => axios.post('/api/v1/internal_incident/editProcedures', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })  

  const deleteProcedure = (options) => axios.post('/api/v1/internal_incident/deleteProcedure', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })  

  const getProcedures = (options) => axios.post('/api/v1/internal_incident/getProcedures', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })  

  const createTemplateSop = (options) => axios.post('/api/v1/internal_incident/createTemplateSop', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const getTemplateSops = (options) => axios.post('/api/v1/internal_incident/getTemplateSops', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const createSops = (options) => axios.post('/api/v1/internal_incident/createSops', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })


  const getSops = (options) => axios.post('/api/v1/internal_incident/getSops', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

  const deleteSop = (options) => axios.post('/api/v1/internal_incident/deleteSop', {
      ...options
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        console.debug(error)
      })

      //

  const createFlashpoint = (options) => axios.post('api/v2/flashpoint/create_flashpoint', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getFlashpoints = (options) => axios.post('api/v2/flashpoint/get_flashpoints', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editFlashpoint = (options) => axios.put('api/v2/flashpoint/edit_flashpoint', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createIssue = (options) => axios.post('api/v2/issue/create_issue', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })


  const getIssues = (options) => axios.post('api/v2/issue/get_issues', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

    //
  const editIssue = (options) => axios.put('api/v2/issue/edit_issue', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createCommitment = (options) => axios.post('api/v2/commitment/create_commitment', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const editCommitment = (options) => axios.put('api/v2/commitment/edit_commitment', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getCommitments = (options) => axios.post('api/v2/commitment/get_commitments', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createPoi = (options) => axios.post('api/v2/poi/create_poi', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })


  const getPois = (options) => axios.post('api/v2/poi/get_pois', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createStakeholder = (options) => axios.post('api/v2/stakeholder/create_stakeholder', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getStakeholders = (options) => axios.post('api/v2/stakeholder/get_stakeholders', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const validateStakeholders = (options) => axios.post('api/v2/stakeholder/validate_stakeholders', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const validateFlashpoints = (options) => axios.post('api/v2/flashpoint/validate_flashpoints', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const validateIssues = (options) => axios.post('api/v2/issue/validate_issues', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const validateNotes = (options) => axios.post('api/v2/engagement_note/validate_notes', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const validateCommitments = (options) => axios.post('api/v2/commitment/validate_commitments', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
  const validatePois = (options) => axios.post('api/v2/poi/validate_pois', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const validateRegionsCounties = (options) => axios.post('api/v2/county/validate_regions', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })


  const createEngagementNote = (options) => axios.post('api/v2/engagement_note/create_note', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getEngagementNotes = (options) => axios.post('api/v2/engagement_note/get_notes', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const createCommunity = (options) => axios.post('api/v2/community/create_community', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  const getCommunities = (options) => axios.post('api/v2/community/get_communities', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })


const createCampaign = (options) => axios.post('api/v2/campaign/create_campaign', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

const getCampaigns = (options) => axios.post('api/v2/campaign/get_campaigns', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
const getCampaignStats = (options) => axios.post('api/v2/campaign/get_campaign_stats', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

const getRecipientsLists = (options) => axios.post('api/v2/campaign/get_lists', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

const getLatestDMs = (options) => axios.post('api/v2/campaign/getLatestDMs', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })
  
const getDMs = (options) => axios.post('api/v2/campaign/getDMs', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

const sendDM = (options) => axios.post('api/v2/campaign/sendDM', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

const submitPulsepoint = (options) => axios.post('api/v2/pulsepoint/submitPulsepoint', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

const getPulsepoints = (options) => axios.post('api/v2/pulsepoint/getPulsepoints', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

const submitTimesheet = (options) => axios.post('api/v2/stakeholder/create_timesheet', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

const getTimesheets = (options) => axios.post('api/v2/stakeholder/get_timesheets', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })

  
const extractStakeholders = (options) => axios.post('/api/v1/domain/extractStakeholders', {
  ...options,
})
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })   
 
const extractNotes = (options) => axios.post('/api/v1/domain/extractNotes', {
  ...options,
})
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })   

const extractIssues = (options) => axios.post('/api/v1/domain/extractIssues', {
  ...options,
})
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })   

const extractPois = (options) => axios.post('/api/v1/domain/extractPois', {
  ...options,
})
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  }) 
  
const extractFlashpoints = (options) => axios.post('/api/v1/domain/extractFlashpoints', {
  ...options,
})
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })

const extractCommitments = (options) => axios.post('/api/v1/domain/extractCommitments', {
  ...options,
})
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })  

const extractCommunities = (options) => axios.post('/api/v1/domain/extractCommunities', {
  ...options,
})
  .then((response) => Promise.resolve(response.data))
  .catch((error) => {
    console.debug(error)
  })
  
  const extractDomains = (options) => axios.post('/api/v1/domain/extractDomains', {
    ...options,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      console.debug(error)
    })  

  return {
    tryIncident,
    tryInsight,
    getIncidents,
    getCounties,
    addBox,
    addMetrics,
    getIncidentsByCounty,
    getIncidentsForCounty,
    tryPath,
    getPaths,
    assignTask,
    getUsers,
    updateStatus,
    archiveTask,
    getSomeTasks,
    editTask,
    getArchivedTasks,
    getJourneys,
    getAirJourneys,
    getAirChapters,
    requestJourney,
    requestAirPreTravel,
    requestAirFlightReq,
    editAirPreTravel,
    progressJourney,
    progressAirJourney,
    denyJourney,
    denyAirJourney,
    commentJourney,
    commentAirJourney,
    activateAccount,
    getRolesbyUser,
    tryWorkspace,
    editUser,
    changePassword,
    editWorkspace,
    inviteToWorkspace,
    processWorkspaceInvite,
    promoteInWorkspace,
    incarnateIntoWorkspace,
    demoteInWorkspace,
    killWorkspace,
    seeWorkspace,
    getWorkspace,
    getMovebox,
    editJourney,
    editIncident,
    editAlertState,
    editMovementBox,
    archiveMovementBox,
    get_avg_alert,
    banishUsers,
    anointUser,
    resetPassword,
    deleteIncident,
    incidentsPerArchangel,
    insightsPerArchangel,
    totalArchangels,
    totalUsers,
    userArchangel,
    archiveUserMetric,
    archiveMetric,
    editMetric,
    editUserMetric,
    createUserMetric,
    getMetricDetailed,
    getMetrics,
    getIrisMetrics,
    getUserMetrics,
    createProject,
    getProjects,
    getProjectDetails,
    createObjective,
    progress_objective,
    regress_objective,
    abort_objective,
    addTaskToObjective,
    getEmail,
    createMessage,
    createRoom,
    getRooms,
    getMessages,
    progress_project,
    regress_project,
    abort_project,
    editObjective,
    editProject,
    createAsset,
    editAsset,
    createAssetRisk,
    createRisk,
    createMitigation,
    editAssetRisk,
    addControlMeasure,
    deprecateControlMeasure,
    removeMitigation,
    getFormMeasures,
    getAssets,
    getAssetsByCounty,
    getAsset,
    getMitigations,
    riskTypes,
    assetTypes,
    archiveAssets,
    archiveAssetRisks,
    getAssetDetails,
    getCountiesOfInterest,
    declareInterestInCounties,
    denounceInterestInCounties,
    help,
    downloadAssets,
    downloadRisks,
    createRiskMetric,
    getAllSafeties,
    promptAllSafeties,
    submitOneSafety,
    promptOneSafety,
    downloadSecurityChange,
    s2ab2,
    downloader2,
    securityPopup,
    archivePath,
    abortAirJourney,
    createBreakout,
    getBreakouts,
    editBreakout,
    leaveBreakout,
    leaveChat,
    changeHost,
    deleteObjectives,
    getLunar,
    situationReport,
    getKeyphrases,
    getAnalyses,
    addAnalyses,
    getMyAnalyses,
    getIncidentsForDomain,
    getInactive,
    getLost,getIncidentsForDomain,
    prompUserSafeties,
    ReportsIncidentPopup,
    getInsightsForDomain,
    dashboardInsights,
    dashboardJourneys,
    dashboardEngagements,
    tester,
    getBoxMeasures,
    weeklyReport,
    weeklyReportPDF,
    weeklyDates,
    createInternalIncident,
    createActionLog,
    createInvestigation,
    voidActionLog,
    closeIncident,
    addInvestigationPeople,
    editInvestigation,
    incidentComment,
    fetchInternalIncident,
    fetchInternalIncidents,
    downloadInternalIncident,
    setEmergencyTeams,
    getEmergencyTeams,
    setResponseLevels,
    getResponseLevels,
    editProcedures,
    createTemplateSop,
    createSops,
    getProcedures,
    deleteProcedure,
    getSops,
    deleteSop,
    getTemplateSops,
    getUserList,
    trackerResponse,
    trackerHit,
    getLocationHistory,
    passwordRecovery,
    MetricByArchangel,
    createFlashpoint,
    createIssue,
    createCommitment,
    createPoi,
    createStakeholder,
    createEngagementNote,
    createCommunity,
    getEngagementNotes,
    getFlashpoints,
    getCommunities,
    getPois,
    getIssues,
    getCommitments,
    getStakeholders,
    editFlashpoint,
    editIssue,
    editCommitment,
    createCampaign,
    getCampaigns,
    getRecipientsLists,
    sendDM,
    getLatestDMs,
    getDMs,
    getCampaignStats,
    userStats,
    projectDropdowns,
    systemSafeties,
    systemPulsepoints,
    getEfficientCounties,
    getAllCounties,
    getAllCountries,
    getAllRegions,
    anonymiseUsers,
    submitPulsepoint,
    getPulsepoints,
    submitTimesheet,
    getTimesheets,
    extractStakeholders,
    extractNotes,
    extractIssues,
    extractPois,
    extractFlashpoints,
    extractCommitments,
    extractCommunities,
    extractDomains,
    dashboardAssets,
    dashboardActivity,
    validateStakeholders,
    validateFlashpoints,
    validateIssues,
    validateNotes,
    validateAssets,
    validateUsers,
    validateCommitments,
    validatePois,
    validateGroups,
    validateRegionsCounties,


    //test


  }
}

export default testFunctionality

/* eslint-enable */
