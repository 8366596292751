/* eslint-disable no-nested-ternary */
import * as React from 'react'
import { useState, useEffect } from 'react'
import {
    IonList, IonLabel, IonButton, IonRow, IonGrid,
    IonCol, IonContent, IonPage, IonItem, IonInput
  } from '@ionic/react'
import { Icon } from '@iconify/react'
import { OverlayContainer } from '../GlobalContainers'
import PageHeader from '../../PageHeader'
import moment from 'moment'

import { SettingsContainer } from '../../settings/StyledContainers'

import useApi from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'

import { SelectView } from '../../maps/StyledContainers'

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts'

import highlyNegative from '../../../components/maps/apps/icons/highlyNegative.png'
import somewhatNegative from '../../../components/maps/apps/icons/somewhatNegative.png'
import neutral from '../../../components/maps/apps/icons/Neutral.png'
import somewhatPositive from '../../../components/maps/apps/icons/somewhatPositive.png'
import highlyPositive from '../../../components/maps/apps/icons/highlyPositive.png'



const InsightsDashboard : React.FC = () => {
  const apiHook = useApi()
  const { workspace } = useWorkspace()

  type GraphData = {
    name: string;
    Journeys: number;
  }

  type ReqData = {
    df_county_sitreps: Array<GraphData>
    df_user_safety_week: Array<GraphData>
    incidents_week: number
    insights_week: number
    safety_week: number
  }

  const [format, setFormat] = useState({})

  const [isMonthly, setIsMonthly] = useState(false)
  const [isDaily, setIsDaily] = useState(false)

  const [view, setView] = useState('Assets')

  const [timeFilter, setTimeFilter] = useState('Weekly')

  const [journeyData, setJourneyData] = useState({})

  const [engagementData, setEngagementData] = useState({})


  const [assetData, setAssetData] = useState({})
  const [activityData, setActivityData] = useState({})

  const [graphData, setGraphData] = useState<ReqData>()

  useEffect(() => {
    const next3 = apiHook.dashboardAssets({
      domain_id: workspace.id,
    })
      .then((data) => {
        setAssetData(data)
        console.log(data)
      })
    const next4 = apiHook.dashboardActivity({
      domain_id: workspace.id,
    })
      .then((data) => {
        setActivityData(data)
        console.log(data)
      })
  }, [])

  const journeysWeek: Array<GraphData> = [
    {
      name: 'Requested',
      Journeys: journeyData.journey_reqs_week,
    },
    {
      name: 'Approved',
      Journeys: journeyData.approved_week,
    },
    {
      name: 'Declined',
      Journeys: journeyData.declined_week,
    },
    {
      name: 'Completed',
      Journeys: journeyData.completed_week,
    },
  ]

  const journeysMonth: Array<GraphData> = [
    {
      name: 'Requested',
      Journeys: journeyData.journey_reqs_month,
    },
    {
      name: 'Approved',
      Journeys: journeyData.approved_month,
    },
    {
      name: 'Declined',
      Journeys: journeyData.declined_month,
    },
    {
      name: 'Completed',
      Journeys: journeyData.completed_month,
    },
  ]

  function handleViewChange(value: string) {
    console.log(value)
    setView(value)
    if (value === 'QuickGo') {
      setIsMonthly(false)
      setTimeFilter('Daily')
    }
  }

  function handleTimeChange(value: string) {
    if (value === 'Weekly') {
      setIsMonthly(false)
      setTimeFilter('Weekly')
    }
    if (value === 'Monthly') {
      setIsMonthly(true)
      setTimeFilter('Monthly')
    }
    if (value === 'Daily') {
      setIsMonthly(false)
      setTimeFilter('Daily')
    }
  }

  const getColor = (value) => {
    if (value === 2) return '#149400'
    if (value === 1) return '#0098ff'
    if (value === 0.5) return '#ffe400'
    if (value === -1) return '#ff8900'
    if (value === -2) return '#c20303'
    return '#8884d8' // Default color
  };

  // Header row styles
  const headerStyle = {
    backgroundColor: '#28464b',
    color: '#fff',
    padding: '8px',
    textAlign: 'left',
    border: '1px solid #ddd', // Border around header cells
  }

  const cellStyle = {
    border: '1px solid #747474',
    padding: '8px',
    maxHeight: '20px', // Set the maximum height
    overflow: 'hidden', // Hide overflow content
    textOverflow: 'ellipsis', // Add ellipsis for overflow text
    whiteSpace: 'nowrap', // Prevent text from wrapping
  }

  return (
    <>
      <IonPage>
        <PageHeader title='Dashboard' />
        <IonContent style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)' }}>
          <IonRow class='ion-align-items-end' style={{ marginTop: '0.2em', height: '10%', marginLeft: '0.3em' }}>
            <IonCol>
              <SelectView style={{ marginLeft: '2em' }} id='incident-time-filter' value={view} onChange={(e) => handleViewChange(e.target.value)}>
                <option value='Assets'>Key Assets</option>
                <option value='Activity'>Scorecard</option>
              </SelectView>
            </IonCol>
            <IonCol style={{ textAlign: 'right' }}>
              <SelectView style={{ marginRight: '2em' }} value={timeFilter} onChange={(e) => handleTimeChange(e.target.value)}>
                <option value='Weekly'>Last Week</option>
                <option value='Monthly'>Last Month</option>
                {
                  view === 'QuickGo' && (
                    <option value='Daily'>Past 24Hrs</option>
                  )
                }
              </SelectView>
            </IonCol>
          </IonRow>

          <IonGrid style={{ height: '100%', marginLeft: '0.3em' }}>
            <IonRow style={{ height: '100%', marginLeft: '0.3em' }}>
              <IonCol size='6' style={{ height: '100%', marginLeft: '0.3em' }}>
                <IonRow style={{ height: '100%', marginLeft: '0.3em' }}>
                  {
                    view === 'Insights' && (
                      <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                        <IonRow style={{ width: '100%', height: '26%', marginBottom: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                              }}
                            >
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    { isMonthly ? (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{format.incidents_month}</IonLabel>
                                    ) : (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{format.incidents_week}</IonLabel>
                                    )}
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol class='ion-align-end'>
                                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold',  }}>Security Reports Submitted</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>

                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                        <IonRow style={{ width: '100%', height: '26%', marginBottom: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                              }}
                            >
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    { isMonthly ? (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{format.insights_month}</IonLabel>
                                    ) : (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{format.insights_week}</IonLabel>
                                    )}
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol class='ion-align-items-end'>
                                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold',  }}>Field Sitreps Submitted</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                        <IonRow style={{ width: '100%', height: '26%', marginBottom: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                              }}
                            >
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    { isMonthly ? (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{format.safety_month}</IonLabel>
                                    ) : (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{format.safety_week}</IonLabel>
                                    )}
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol>
                                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', }}>Team Safety Checks Completed</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                      </IonCol>
                    )
                  }

                  {
                    view === 'Assets' && (
                      <IonCol style={{ height: '100%', width: '45%', marginLeft: '0.3em' }}>
                        <IonRow style={{ height: '100%', marginLeft: '0.3em' }}>
                          <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                            <IonRow style={{ width: '97%', height: '40%', marginBottom: '1em', marginRight: '1em' }}>
                              <ResponsiveContainer width='100%' height='65%'>
                                { isMonthly ? (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th style={headerStyle}>Items</th>
                                        <th style={headerStyle}>7 Days</th>
                                        <th style={headerStyle}>30 Days</th>
                                        <th style={headerStyle}>90 Days</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={cellStyle}>Pulse Points</td>
                                        <td style={cellStyle}>{assetData.table_pulse_week}</td>
                                        <td style={cellStyle}>{assetData.table_pulse_month}</td>
                                        <td style={cellStyle}>{assetData.table_pulse_90}</td>
                                      </tr>
                                      <tr>
                                        <td style={cellStyle}>Engagements</td>
                                        <td style={cellStyle}>{assetData.table_engagement_week}</td>
                                        <td style={cellStyle}>{assetData.table_engagement_month}</td>
                                        <td style={cellStyle}>{assetData.table_engagement_90}</td>
                                      </tr>
                                      <tr>
                                        <td style={cellStyle}>Issues</td>
                                        <td style={cellStyle}>{assetData.table_issue_week}</td>
                                        <td style={cellStyle}>{assetData.table_issue_month}</td>
                                        <td style={cellStyle}>{assetData.table_issue_90}</td>
                                      </tr>
                                      <tr>
                                        <td style={cellStyle}>Incidents</td>
                                        <td style={cellStyle}>{assetData.table_incident_week}</td>
                                        <td style={cellStyle}>{assetData.table_incident_month}</td>
                                        <td style={cellStyle}>{assetData.table_incident_90}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                ) : (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th style={headerStyle}>Items</th>
                                        <th style={headerStyle}>7 Days</th>
                                        <th style={headerStyle}>30 Days</th>
                                        <th style={headerStyle}>90 Days</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={cellStyle}>Pulse Points</td>
                                        <td style={cellStyle}>{assetData.table_pulse_week}</td>
                                        <td style={cellStyle}>{assetData.table_pulse_month}</td>
                                        <td style={cellStyle}>{assetData.table_pulse_90}</td>
                                      </tr>
                                      <tr>
                                        <td style={cellStyle}>Engagements</td>
                                        <td style={cellStyle}>{assetData.table_engagement_week}</td>
                                        <td style={cellStyle}>{assetData.table_engagement_month}</td>
                                        <td style={cellStyle}>{assetData.table_engagement_90}</td>
                                      </tr>
                                      <tr>
                                        <td style={cellStyle}>Issues</td>
                                        <td style={cellStyle}>{assetData.table_issue_week}</td>
                                        <td style={cellStyle}>{assetData.table_issue_month}</td>
                                        <td style={cellStyle}>{assetData.table_issue_90}</td>
                                      </tr>
                                      <tr>
                                        <td style={cellStyle}>Incidents</td>
                                        <td style={cellStyle}>{assetData.table_incident_week}</td>
                                        <td style={cellStyle}>{assetData.table_incident_month}</td>
                                        <td style={cellStyle}>{assetData.table_incident_90}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )}
                              </ResponsiveContainer>
                            </IonRow>
                            <IonRow style={{ width: '97%', height: '40%', marginBottom: '1em', marginRight: '1em' }}>
                              <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                                <IonRow
                                  class='ion-justify-content-center'
                                  style={{
                                    height: '15%',
                                    backgroundColor: '#FFFFFF',
                                    boxShadow: 'none',
                                    width: '100%',
                                  }}
                                >
                                  <IonLabel style={{ color: '#28464b', fontSize: '1em', fontWeight: 'bold', marginTop: '0.3em', textAlign: 'left' }}>Community Sentiment</IonLabel>
                                </IonRow>
                                <IonRow
                                  class='ion-justify-content-center'
                                  style={{
                                    height: '85%',
                                    backgroundColor: '#FFFFFF',
                                    boxShadow: 'none',
                                    width: '100%',
                                  }}
                                >
                                  <IonCol size={1}>
                                    <IonRow style={{ marginLeft: '0.1em', marginBottom: '0.3em ' }}>
                                      <img src={highlyPositive} alt={'highlypositive'} style={{ height: '30px', width: '30px' }} />
                                    </IonRow>
                                    <IonRow style={{ marginLeft: '0.1em', marginBottom: '0.3em ' }}>
                                      <img src={somewhatPositive} alt={'somewhatpositive'} style={{ height: '30px', width: '30px' }} />
                                    </IonRow>
                                    <IonRow style={{ marginLeft: '0.1em', marginBottom: '0.3em ' }}>
                                      <img src={neutral} alt={'neutral'} style={{ height: '30px', width: '30px' }} />
                                    </IonRow>
                                    <IonRow style={{ marginLeft: '0.1em', marginBottom: '0.3em ' }}>
                                      <img src={somewhatNegative} alt={'somewhatnegative'} style={{ height: '30px', width: '30px' }} />
                                    </IonRow>
                                    <IonRow style={{ marginLeft: '0.1em', marginBottom: '0.3em ' }}>
                                      <img src={highlyNegative} alt={'highlynegative'} style={{ height: '30px', width: '30px' }} />
                                    </IonRow>
                                  </IonCol>
                                  <IonCol size={11}>
                                    <ResponsiveContainer width='100%' height='90%'>
                                      {isMonthly ? (
                                        <BarChart
                                          data={assetData.sentiment_month}
                                          margin={{
                                            top: 5,
                                            bottom: 5,
                                            right: 5,
                                          }}
                                        >
                                          <CartesianGrid vertical={false} horizontalPoints={[0]} />
                                          <XAxis dataKey='name' axisLine={false} tickLine={false} />
                                          <YAxis axisLine={false} tickLine={false} tick={false} />
                                          <Tooltip
                                            formatter={(value) => {
                                              let sentimentLabel
                                              let color
                                              switch (value) {
                                                case -2:
                                                  sentimentLabel = 'Highly Negative'
                                                  color = '#c20303'
                                                  break
                                                case -1:
                                                  sentimentLabel = 'Somewhat Negative'
                                                  color = '#ff8900'
                                                  break
                                                case 0.5:
                                                  sentimentLabel = 'Neutral'
                                                  color = '#ffe400'
                                                  break
                                                case 1:
                                                  sentimentLabel = 'Somewhat Positive'
                                                  color = '#0098ff'
                                                  break
                                                case 2:
                                                  sentimentLabel = 'Highly Positive'
                                                  color = '#149400'
                                                  break
                                                default:
                                                  sentimentLabel = 'None'
                                                  color = '#808080'
                                              }
                                              return [<span style={{ color }}>{sentimentLabel}</span>, 'Sentiment']
                                            }}
                                          />
                                          <ReferenceLine y={0} stroke='#000' />
                                          <Bar dataKey='Sentiment'>
                                            {assetData.sentiment_month && assetData.sentiment_month.map((entry, index) => (
                                              <Cell key={`cell-${index}`} fill={getColor(entry.Sentiment)} />
                                            ))}
                                          </Bar>
                                        </BarChart>
                                      ) : (
                                        <BarChart
                                          data={assetData.sentiment_week}
                                          margin={{
                                            top: 5,
                                            bottom: 5,
                                            right: 5,
                                          }}
                                        >
                                          <CartesianGrid vertical={false} horizontalPoints={[0]} />
                                          <XAxis dataKey='name' axisLine={false} tickLine={false} />
                                          <YAxis axisLine={false} tickLine={false} tick={false} />
                                          <Tooltip
                                            formatter={(value) => {
                                              let sentimentLabel
                                              let color
                                              switch (value) {
                                                case -2:
                                                  sentimentLabel = 'Highly Negative'
                                                  color = '#c20303'
                                                  break
                                                case -1:
                                                  sentimentLabel = 'Somewhat Negative'
                                                  color = '#ff8900'
                                                  break
                                                case 0.5:
                                                  sentimentLabel = 'Neutral'
                                                  color = '#ffe400'
                                                  break
                                                case 1:
                                                  sentimentLabel = 'Somewhat Positive'
                                                  color = '#0098ff'
                                                  break
                                                case 2:
                                                  sentimentLabel = 'Highly Positive'
                                                  color = '#149400'
                                                  break
                                                default:
                                                  sentimentLabel = 'None'
                                                  color = '#808080'
                                              }
                                              return [<span style={{ color }}>{sentimentLabel}</span>, 'Sentiment']
                                            }}
                                          />
                                          <ReferenceLine y={0} stroke='#000' />
                                          <Bar dataKey='Sentiment'>
                                            {assetData.sentiment_week && assetData.sentiment_week.map((entry, index) => (
                                              <Cell key={`cell-${index}`} fill={getColor(entry.Sentiment)} />
                                            ))}
                                          </Bar>
                                        </BarChart>
                                      )}
                                    </ResponsiveContainer>
                                  </IonCol>
                                </IonRow>
                              </SettingsContainer>
                            </IonRow>

                          </IonCol>
                        </IonRow>
                      </IonCol>
                    )
                  }

                  {
                    view === 'Activity' && (
                      <IonCol style={{ width: '100%', marginLeft: '0.3em' }}>
                        <IonRow style={{ marginLeft: '0.3em' }}>
                          <ResponsiveContainer width='100%' height='65%'>
                            { isMonthly ? (
                              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                  <tr>
                                    <th style={headerStyle}>Rank</th>
                                    <th style={headerStyle}>Name</th>
                                    <th style={headerStyle}>Total Points</th>
                                    <th style={headerStyle}>Sherpa Vanguard</th>
                                    <th style={headerStyle}>Personal Safety</th>
                                    <th style={headerStyle}>Situational Awareness</th>
                                    <th style={headerStyle}>Core Knowledge</th>
                                    <th style={headerStyle}>Community Engagement</th>
                                    <th style={headerStyle}>Last Activity</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {activityData?.activity_month?.map((item, index) => (
                                    <tr>
                                      <td style={cellStyle}>{index + 1}</td>
                                      <td style={cellStyle}>{item.name}</td>
                                      <td style={cellStyle}>{item.total_points}</td>
                                      <td style={cellStyle}>{item.sherpa_vanguard}</td>
                                      <td style={cellStyle}>{item.personal_safety}</td>
                                      <td style={cellStyle}>{item.situational_awareness}</td>
                                      <td style={cellStyle}>{item.core_knowledge}</td>
                                      <td style={cellStyle}>{item.community_engagement}</td>
                                      <td style={cellStyle}>{ moment(item.last_activity).format('DD-MMM') }</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                  <tr>
                                    <th style={headerStyle}>Rank</th>
                                    <th style={headerStyle}>Name</th>
                                    <th style={headerStyle}>Total Points</th>
                                    <th style={headerStyle}>Sherpa Vanguard</th>
                                    <th style={headerStyle}>Personal Safety</th>
                                    <th style={headerStyle}>Situational Awareness</th>
                                    <th style={headerStyle}>Core Knowledge</th>
                                    <th style={headerStyle}>Community Engagement</th>
                                    <th style={headerStyle}>Last Activity</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {activityData?.activity_week?.map((item, index) => (
                                    <tr>
                                      <td style={cellStyle}>{index + 1}</td>
                                      <td style={cellStyle}>{item.name}</td>
                                      <td style={cellStyle}>{item.total_points}</td>
                                      <td style={cellStyle}>{item.sherpa_vanguard}</td>
                                      <td style={cellStyle}>{item.personal_safety}</td>
                                      <td style={cellStyle}>{item.situational_awareness}</td>
                                      <td style={cellStyle}>{item.core_knowledge}</td>
                                      <td style={cellStyle}>{item.community_engagement}</td>
                                      <td style={cellStyle}>{ moment(item.last_activity).format('DD-MMM') }</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                          </ResponsiveContainer>
                        </IonRow>
                      </IonCol>
                    )
                  }


                  {
                    view === 'Engagement' && (
                      <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                        <IonRow style={{ width: '100%', height: '26%', marginBottom: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                              }}
                            >
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    { isMonthly ? (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{engagementData?.notes_month}</IonLabel>
                                    ) : (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{engagementData?.notes_week}</IonLabel>
                                    )}
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol class='ion-align-end'>
                                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', }}>Engagement Notes Added</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>

                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                        <IonRow style={{ width: '100%', height: '26%', marginBottom: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                              }}
                            >
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    { isMonthly ? (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{engagementData?.stakeholders_month}</IonLabel>
                                    ) : (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{engagementData?.stakeholders_week}</IonLabel>
                                    )}
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol class='ion-align-items-end'>
                                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', }}>Stakeholders Added</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                        <IonRow style={{ width: '100%', height: '26%', marginBottom: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                              }}
                            >
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    { isMonthly ? (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{engagementData?.issues_month}</IonLabel>
                                    ) : (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.8em' }}>{engagementData?.issues_week}</IonLabel>
                                    )}
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol>
                                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold' }}>Issues Added</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                      </IonCol>
                    )
                  }

                  {
                    view === 'Journeys' && (
                      <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                        <IonRow style={{ width: '100%', height: '20%', marginBottom: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                              }}
                            >
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    { isMonthly ? (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.7em' }}>{journeyData.journey_reqs_month}</IonLabel>
                                    ) : (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.7em' }}>{journeyData.journey_reqs_week}</IonLabel>
                                    )}
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol class='ion-align-end'>
                                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold',  }}>Journey Requests Submitted</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>

                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                        <IonRow style={{ width: '100%', height: '20%', marginBottom: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                              }}
                            >
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    { isMonthly ? (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.7em' }}>{journeyData.approved_month}</IonLabel>
                                    ) : (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.7em' }}>{journeyData.approved_week}</IonLabel>
                                    )}
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol class='ion-align-items-end'>
                                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold',  }}>Approved Journey Requests</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                        <IonRow style={{ width: '100%', height: '20%', marginBottom: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                              }}
                            >
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    { isMonthly ? (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.7em' }}>{journeyData.declined_month}</IonLabel>
                                    ) : (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.7em' }}>{journeyData.declined_week}</IonLabel>
                                    )}
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol>
                                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', }}>Declined Journey Requests</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                        <IonRow style={{ width: '100%', height: '20%', marginBottom: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                width: '100%',
                              }}
                            >
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    { isMonthly ? (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.7em' }}>{journeyData.completed_month}</IonLabel>
                                    ) : (
                                      <IonLabel style={{ fontWeight: 'bold', fontSize: '2em', marginLeft: '0.5em', color: 'black', marginTop: '0.7em' }}>{journeyData.completed_week}</IonLabel>
                                    )}
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol>
                                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', }}>Journeys Completed</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                      </IonCol>
                    )
                  }
                </IonRow>
              </IonCol>

              {
                view === 'Insights' && (
                  <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>

                    <IonRow style={{ height: '100%', marginLeft: '0.3em' }}>
                      <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                        <IonRow style={{ width: '97%', height: '40%', marginBottom: '1em', marginRight: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              class='ion-justify-content-center'
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                width: '100%',
                              }}
                            >
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', marginTop: '0.3em'}}>User Safety Breakdown</IonLabel>
                              <ResponsiveContainer width='100%' height='65%'>
                                { isMonthly ? (
                                  <BarChart
                                    data={format.df_user_safety_month}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Green' stackId='a' fill='#14C105' />
                                    <Bar dataKey='Amber' stackId='a' fill='#FF8A00' />
                                    <Bar dataKey='Red' stackId='a' fill='#DD2800' />
                                    <Bar dataKey='Dismissed' stackId='a' fill='#326771' />                              </BarChart>
                                ) : (
                                  <BarChart
                                    data={format.df_user_safety_week}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Green' stackId='a' fill='#14C105' />
                                    <Bar dataKey='Amber' stackId='a' fill='#FF8A00' />
                                    <Bar dataKey='Red' stackId='a' fill='#DD2800' />
                                    <Bar dataKey='Dismissed' stackId='a' fill='#326771' />
                                  </BarChart>
                                )}

                              </ResponsiveContainer>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>
                        <IonRow style={{ width: '97%', height: '40%', marginBottom: '1em', marginRight: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              class='ion-justify-content-center'
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                width: '100%',
                              }}
                            >
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', marginTop: '0.3em' }}>Field Sitreps by County</IonLabel>
                              <ResponsiveContainer width='100%' height='65%'>
                                { isMonthly ? (
                                  <BarChart
                                    data={format.df_county_sitreps_month}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Field Sitreps' fill='#2596be' />
                                  </BarChart>
                                ) : (
                                  <BarChart
                                    data={format.df_county_sitreps_week}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Field Sitreps' fill='#2596be' />
                                  </BarChart>
                                )}
                              </ResponsiveContainer>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                      </IonCol>
                    </IonRow>
                  </IonCol>
                )
              }
              {
                view === 'Engagement' && (
                  <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>

                    <IonRow style={{ height: '100%', marginLeft: '0.3em' }}>
                      <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                        <IonRow style={{ width: '97%', height: '40%', marginBottom: '1em', marginRight: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              class='ion-justify-content-center'
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                width: '100%',
                              }}
                            >
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', marginTop: '0.3em'}}>Engagements by Community</IonLabel>
                              <ResponsiveContainer width='100%' height='65%'>
                                { isMonthly ? (
                                  <BarChart
                                    data={engagementData?.df_notes_by_comm_month}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Notes' fill='#2596be' />
                                  </BarChart>
                                ) : (
                                  <BarChart
                                    data={engagementData?.df_notes_by_comm_week}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Notes' fill='#2596be' />
                                  </BarChart>
                                )}

                              </ResponsiveContainer>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>
                        <IonRow style={{ width: '97%', height: '40%', marginBottom: '1em', marginRight: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              class='ion-justify-content-center'
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                width: '100%',
                              }}
                            >
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', marginTop: '0.3em' }}>Engagements by Stakeholder</IonLabel>
                              <ResponsiveContainer width='100%' height='65%'>
                                { isMonthly ? (
                                  <BarChart
                                    data={engagementData?.df_notes_by_stakeholders_month}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Notes' fill='#2596be' />
                                  </BarChart>
                                ) : (
                                  <BarChart
                                    data={engagementData?.df_notes_by_stakeholders_week}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Notes' fill='#2596be' />
                                  </BarChart>
                                )}
                              </ResponsiveContainer>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                      </IonCol>
                    </IonRow>
                  </IonCol>
                )
              }
              {
                view === 'Journeys' && (
                  <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                    <IonRow style={{ height: '100%', marginLeft: '0.3em' }}>
                      <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                        <IonRow style={{ width: '97%', height: '88%', marginBottom: '1em', marginRight: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              class='ion-justify-content-center'
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                width: '100%',
                              }}
                            >
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', marginTop: '0.3em'}}>Journeys Visualised</IonLabel>
                              <ResponsiveContainer width='100%' height='90%'>
                                { isMonthly ? (
                                  <BarChart
                                    data={journeysMonth}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Journeys' fill='#2596be' />
                                  </BarChart>
                                ) : (
                                  <BarChart
                                    data={journeysWeek}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Journeys' fill='#2596be' />
                                  </BarChart>
                                )
                              }
                              </ResponsiveContainer>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                )
              }
              {
                view === 'QuickGo' && (
                  <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                    <IonRow style={{ height: '100%', marginLeft: '0.3em' }}>
                      <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                        <IonRow style={{ width: '97%', height: '80%', marginBottom: '1em', marginRight: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              class='ion-justify-content-center'
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                width: '100%',
                              }}
                            >
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', marginTop: '0.3em'}}>QuickGo by County</IonLabel>
                              <ResponsiveContainer width='100%' height='95%'>
                                {timeFilter === 'Daily' ? (
                                  <BarChart
                                    data={journeyData?.df_qg_by_user_daily}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <XAxis dataKey='name' />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey='QuickGos' fill='#d35400' />
                                  </BarChart>
                                ) : timeFilter === 'Monthly' ? (
                                  <BarChart
                                    data={journeyData?.df_qg_by_user_month}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <XAxis dataKey='name' />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey='QuickGos' fill='#2596be' />
                                  </BarChart>
                                ) : (
                                  <BarChart
                                    data={journeyData?.df_qg_by_user_week}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <XAxis dataKey='name' />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey='QuickGos' fill='#2596be' />
                                  </BarChart>
                                )}
                              </ResponsiveContainer>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                )
              }
              {
                view === 'Pulsepoint' && (
                  <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                    <IonRow style={{ height: '100%', marginLeft: '0.3em' }}>
                      <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                        <IonRow style={{ width: '97%', height: '88%', marginBottom: '1em', marginRight: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              class='ion-justify-content-center'
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                width: '100%',
                              }}
                            >
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', fontWeight: 'bold', marginTop: '0.3em'}}>Pulsepoints Visualised</IonLabel>
                              <ResponsiveContainer width='100%' height='90%'>
                                { isMonthly ? (
                                  <BarChart
                                    data={journeyData.df_pulsepoints_by_users_month}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Pulsepoints' fill='#2596be' />
                                  </BarChart>
                                ) : (
                                  <BarChart
                                    data={journeyData.df_pulsepoints_by_users_week}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Pulsepoints' fill='#2596be' />
                                  </BarChart>
                                )}
                              </ResponsiveContainer>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                )
              }

              {
                view === 'Assets' && (
                  <IonCol style={{ height: '100%', width: '45%', marginLeft: '0.3em' }}>

                    <IonRow style={{ height: '100%', marginLeft: '0.3em' }}>
                      <IonCol style={{ height: '100%', marginLeft: '0.3em' }}>
                        <IonRow style={{ width: '97%', height: '40%', marginBottom: '1em', marginRight: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              class='ion-justify-content-center'
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                width: '100%',
                                textAlign: 'left',
                              }}
                            >
                              <IonLabel style={{ color: '#28464b', fontSize: '1em', fontWeight: 'bold', marginTop: '0.3em', textAlign: 'left' }}>Community Engagement</IonLabel>
                              <ResponsiveContainer width='100%' height='65%'>
                                { isMonthly ? (
                                  <BarChart
                                    data={assetData.engagement_month}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Engagement Notes' fill='#14C105' />
                                  </BarChart>
                                ) : (
                                  <BarChart
                                    data={assetData.engagement_week}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Engagement Notes' fill='#14C105' />
                                  </BarChart>
                                )}

                              </ResponsiveContainer>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>
                        <IonRow style={{ width: '97%', height: '40%', marginBottom: '1em', marginRight: '1em' }}>
                          <SettingsContainer style={{ width: '100%', height: '100%', padding: '0px', boxShadow: 'none' }}>
                            <IonRow
                              class='ion-justify-content-center'
                              style={{
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                width: '100%',
                              }}
                            >
                              <IonLabel style={{ color: '#28464b', fontSize: '1em', fontWeight: 'bold', marginTop: '0.3em' }}>Community Issues</IonLabel>
                              <ResponsiveContainer width='100%' height='65%'>
                                { isMonthly ? (
                                  <BarChart
                                    data={assetData.issues_month}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Issues' fill='#2596be' />
                                  </BarChart>
                                ) : (
                                  <BarChart
                                    data={assetData.issues_week}
                                    margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray='1 1' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount='3' />
                                    <Tooltip />
                                    <Bar dataKey='Issues' fill='#2596be' />
                                  </BarChart>
                                )}
                              </ResponsiveContainer>
                            </IonRow>
                          </SettingsContainer>
                        </IonRow>

                      </IonCol>
                    </IonRow>
                  </IonCol>
                )
              }
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  )
}

export default InsightsDashboard
